import React, { useState } from 'react';
import classNames from 'classnames';

import { titleEl, questionBlock, questionBlockExpanded, questionEl, answerEl, expandIcon } from './DemoFaq.module.scss'; 

const DemoFaq = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const questionBlockClasses = classNames(questionBlock, expanded && questionBlockExpanded);

  return (
    <div className={questionBlockClasses} onClick={() => { setExpanded(!expanded) }}>
      <h3 className={questionEl}>
        {question}
        <div className={expandIcon}></div>
      </h3>
      <div className={answerEl}>
        {answer}
      </div>
    </div>
  );
}

const DemoFaqList = ({ data }) => {
  return (
    <>
      <h2 className={classNames('demoDetailsTitle', titleEl)}>F.A.Q. on the project</h2>
      {
        data.map((faqData, index) => <DemoFaq key={index} {...faqData} />)
      }
    </>
  );
}

export default DemoFaqList;

