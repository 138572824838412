import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import { content, xBtn } from './Modal.module.scss';

ReactModal.setAppElement('body');

const Modal = ({ isOpen, onClose, label='', shouldCloseOnOverlayClick=true, style, children }) => {
    const contentClasses = classNames(content, style && style);

    return (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          contentLabel={label}
          className={contentClasses}
        >
            {children}
            <div className={xBtn} onClick={onClose}></div>
        </ReactModal>
    );
};

export default Modal;
