import React from 'react';
import classNames from 'classnames';

export const BUTTON_STYLES = {
  BASIC: 'BASIC',
  CTA: 'CTA'
}

const Button = ({ type='button', style=BUTTON_STYLES.BASIC, isPressed=false,  disabled=false, children, onClick }) => {
  const clickHandler = () => {
    if (disabled) {
      return;
    }

    onClick && onClick();
  };

  const buttonClasses = classNames(
    'btn',
    style === BUTTON_STYLES.BASIC && 'basic',
    style === BUTTON_STYLES.CTA && 'cta',
    isPressed && 'pressed'
  );

  return (
    <button
      type={type}
      className={buttonClasses}
      disabled={disabled}
      onClick={clickHandler}>
      {children}
    </button>
  );
};

export default Button;
