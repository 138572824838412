import React from 'react';
import classNames from 'classnames';

import Modal from '../../Modal';
import { modalWrap, success, error, title } from './SignUpResultModal.module.scss'; 

const SignUpResultModal = ({ isOpen, onClose, isSuccess=true, label='' }) => {
    const modalClasses = classNames(modalWrap, isSuccess ? success : error);

    return (
        <Modal
          isOpen={isOpen}
          label={label}
          onClose={onClose}
          shouldCloseOnOverlayClick={true}
          style={modalClasses}
        >
            <h2 className={title}>
                { isSuccess ? "Thank you!" : "Something wrong happened" }
            </h2>
        </Modal>
    );
};

export default SignUpResultModal;
