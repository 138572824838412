import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getTags } from "../../services/api";
import classNames from "classnames";
import "./tags-menu.scss";

const TagsMenu = () => {
  const [tags, setTags] = useState([]);
  const { pathname } = useLocation();
  const [condition, setCondition] = useState(false);

  const containerClasses = classNames("tagsMenu", condition && "tagsMenuMobile");
  const btnClasses = classNames("toggleTagsMenu", condition && "active");

  useEffect(() => {
    getTags().then((data) => {
      setTags(data);
    });
  }, []);

  return (
    <div className={containerClasses} onClick={() => setCondition(!condition)}>
      <button className={btnClasses}></button>
      <nav>
        <div className="container">
          <ul className={condition ? "is-open" : null}>
            <li onClick={() => setCondition(false)}>
              <NavLink to="/" className={pathname === "/" ? "selected" : ""}>
                All
              </NavLink>
            </li>
            {tags.map((tag, index) => {
              const url = "/category/" + tag.split(" ").join("-");

              return (
                <li key={index} onClick={() => setCondition(false)}>
                  <NavLink to={url} activeClassName="selected">
                    {tag}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TagsMenu;
