import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';

import { wrap, titleEl } from './DemoExploreCTA.module.scss'; 

const DemoExploreCTA = ({ demoUrl }) => {
  return (
    <div className={wrap}>
      <h2 className={classNames('demoDetailsTitle', titleEl)}>Explore our demo!</h2>
      <Link className="btn cta" to={demoUrl}>Explore</Link>
    </div>
  );
}

export default DemoExploreCTA;
