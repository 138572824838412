import React from "react";
import { bottom } from "./Footer.module.scss";
import { ReactComponent as FacebookIcon } from "../../assets/icons/fb.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className={bottom}>
          <ul>
            <li>
              © 2006 - {currentYear}{" "}
              <a target="_blank" href="https://www.griddynamics.com/">
                GridDynamics
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.griddynamics.com/privacy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.griddynamics.com/terms-and-conditions">
                Terms of Use
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.griddynamics.com/">
                GDPR
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.griddynamics.com/">
                Compliance Statement
              </a>
            </li>
          </ul>

          <ul>
            <li>+1 (650) 523-5000 info@griddynamics.com</li>
            <li>
              <a target="_blank" href="https://www.facebook.com/griddynamics/">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/GridDynamics">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.linkedin.com/company/grid-dynamics">
                <LinkedinIcon style={{marginTop: "-3px"}} />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.youtube.com/channel/UCFX-U3YT1ANC907BzvhNn7Q">
                <YoutubeIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
