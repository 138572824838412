import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content';
import ScrollToTop from './components/ScrollToTop';

import './styles/main.scss';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Content />
      <Footer />
    </Router>
  );
};

export default App;
