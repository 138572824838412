import React from 'react';

import { listWrap, technologyWrap, technologyImg } from './DemoTechnologies.module.scss'; 

const DemoTechnology = ({ name, icon }) => {
  return (
    <li className={technologyWrap} title={name}>
      <img src={icon} className={technologyImg} alt={name} />
    </li>
  );
}

const DemoTechnologies = ({ data }) => {
  return (
    <ul className={listWrap}>
      {
        data.map((data, index) => <DemoTechnology key={index} {...data} />)
      }
    </ul>
  );
}

export default DemoTechnologies;
