import React from 'react';
import { Route, Redirect } from "react-router-dom";

import { auth as authApi } from "../../services/api";

const GuestOnlyRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        authApi.isPublicSignupRequired() && !authApi.isUserLoggedIn() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}
  
export default GuestOnlyRoute;
