import React from 'react';
import classNames from 'classnames';

import { wrap, dividerContainerEl, dividerEl, inner } from './DemoDetailsSection.module.scss'; 

const DemoDetailsSection = ({ noDivider, children, style, type }) => {
  const wrapClasses = classNames(wrap, style && style );

  return (
    <div data-type={type} className={wrapClasses}>
      {
        !noDivider ? <div className={dividerContainerEl}><div className={dividerEl}></div></div> : null
      }
      <div className={inner}>
        {children}
      </div>
    </div>
  );
}

export default DemoDetailsSection;
