import React, { useEffect, useState } from "react";
import { frame } from "./DemoPage.module.scss";
import { useParams } from "react-router-dom";
import { getDemoAppData } from "../../services/api";
import Breadcrumbs from "../../components/Breadcrumbs";

const DemoPage = () => {
  const [demoApp, setDemoApp] = useState();
  const { category, id } = useParams();
  const categoryTitle = category.split("-").join(" ");
  const demoTitle = id.split("-").join(" ");
  const breadcrumbsLinks = [
    {
      url: `/category/${category}`,
      text: categoryTitle,
    },
    {
      url: `/category/${category}/demo/${id}`,
      text: demoTitle,
    },
    {
      url: null,
      text: "Explore",
    },
  ];

  useEffect(() => {
    getDemoAppData(id).then((data) => {
      setDemoApp(data);
    });
  }, [id]);

  if (!demoApp) {
    return null;
  }

  return (
    <>
      <div className="container">
        <Breadcrumbs links={breadcrumbsLinks} />
      </div>
      <iframe
        title={demoApp.name}
        src={demoApp.link}
        className={frame}
        allow="camera *"
      ></iframe>
    </>
  );
};

export default DemoPage;
