import React from 'react';
import classNames from 'classnames';

import { titleEl, testimonialsWrap, testimonialWrap, testimonialImg, testimonialText, testimonialAbout, testimonialName, testimonialPosition } from './DemoTestimonials.module.scss'; 

const Testimonial = ({ img, text, full_name, position }) => {
  return (
    <li className={testimonialWrap}>
      <img src={img} className={testimonialImg} alt={full_name} />
      <div className={testimonialText}>{text}</div>
      <div className={testimonialAbout}>
        <span className={testimonialName}>{full_name},</span> { position && <span className={testimonialPosition}>{position}</span> }
      </div>
    </li>
  );
}

const DemoTestimonials = ({ data }) => {
  return (
    <>
      <h2 className={classNames('demoDetailsTitle', titleEl)}>Testimonials</h2>
      <ul className={testimonialsWrap}>
          {
            data.map((data, index) => <Testimonial key={index} {...data} />)
          }
      </ul>
    </>
  );
}

export default DemoTestimonials;
