import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import GuestOnlyRoute from './GuestOnlyRoute';

import HomePage from '../pages/HomePage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';

const RouteSchema = () => (
  <main>
    <Switch>
      <GuestOnlyRoute exact path="/auth/signup"><SignUpPage /></GuestOnlyRoute>
      <PrivateRoute path="/"><HomePage /></PrivateRoute>
    </Switch>
  </main>
);

export default RouteSchema;
