import React from "react";

import SignUpForm from "../../components/SignUpForm";
import AnimatedLogInImg from '../../assets/animated_log_in.svg';

import { page, pageInner, bgImg, formWrap } from './SignUpPage.module.scss';

const SignUpPage = () => {
  return (
    <div className={page}>
      <object type="image/svg+xml" data={AnimatedLogInImg} className={bgImg}>svg-animation</object>
      <div className={pageInner}>
        <div className={formWrap}>
          <SignUpForm />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
