import React from "react";
import { NavLink } from "react-router-dom";
import { breadcrumbs } from "./Breadcrumbs.module.scss";


const Breadcrumbs = ({ links }) => {
  const undefinedCategory = '_';

  return (
    <ul className={breadcrumbs}>
      <li>
        <NavLink to="/">Demos home</NavLink>
      </li>
      {links.map(({ url, text }, index) => (
        text !== undefinedCategory && <li key={index}>{url ? <NavLink to={url}>{text}</NavLink> : text}</li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
