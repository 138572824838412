import React from "react";
import {
  titleEl,
  featuresWrap,
  featureWrap,
  featureImg,
  featureTitle,
  featureText,
} from "./DemoFeatures.module.scss";
import classNames from "classnames";

const Feature = ({ title, text, type }) => {
  return (
    <li className={featureWrap}>
      <figure className={featureImg}>
        <img src={type.icon} alt={type.name} />
      </figure>
      <div className={featureTitle}>{title}</div>
      <div className={featureText}>{text}</div>
    </li>
  );
};

const DemoFeatures = ({ data }) => {
  return (
    <>
      <h2 className={classNames("demoDetailsTitle", titleEl)}>Key features</h2>
      <ul className={featuresWrap}>
        {data.map((data, index) => (
          <Feature key={index} {...data} />
        ))}
      </ul>
    </>
  );
};

export default DemoFeatures;
