const cardImgDefault = 'card-default.png';

export const mapDemo = ({ id, sections }) => ({
  id,
  sections,
});

export const mapDemoCard = (demo) => ({
  id: demo.id,
  title: demo.card_title,
  description: demo.card_description,
  img: demo.card_img || demo.card_thumbnail_link || cardImgDefault,
  tags: (demo.tags && demo.tags.map(({ title }) => title)) || [],
});
