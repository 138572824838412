import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import classNames from 'classnames';

import { inputElWrap, inputEl, withError, focused, errorEl, inputPlaceholder, inputPlaceholderRequired } from './Input.module.scss';

const Input = ({ name, placeholder, style, ...rest }) => {
    const inputRef = useRef(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    const [value, setValue] = useState('');
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }, [fieldName, registerField]);

    const Placeholder = (title, isRequired) => {
        return (
            Boolean(title) ? <div className={inputPlaceholder} onClick={onClickPlaceholderHandler}>{title}{isRequired && <span className={inputPlaceholderRequired}>*</span>}</div> : null
        );
    };

    const onClickPlaceholderHandler = () => {
        onInputFocusHandler();
        inputRef.current.focus();
    };

    const onInputFocusHandler = () => {
        setIsActive(true);
    };

    const onInputBlurHandler = () => {
        setIsActive(false);
    };

    const onInputChangeHandler = (e) => {
        setValue(e.target.value);
    };

    const inputContainerClasses = classNames(
        style,
    );

    const inputElClasses = classNames(
        inputEl,
        error && withError,
        isActive && focused,
    );

    return (
        <div className={inputContainerClasses}>
            <div className={inputElWrap}>
                <input onChange={onInputChangeHandler} onFocus={onInputFocusHandler} onBlur={onInputBlurHandler} ref={inputRef} defaultValue={defaultValue} className={inputElClasses} name={name} value={value} {...rest} />
                { error && <span className={errorEl}>{error}</span> }
                { !isActive && !Boolean(value) && Placeholder(placeholder, Boolean(rest.required)) }
            </div>
        </div>
    );
  }

export default Input;
