const set = (key, val) => {
    window.localStorage.setItem(key, val);
};

const get = (key) => {
    return window.localStorage.getItem(key);
};
  
const remove = (key) => {
    window.localStorage.removeItem(key);
};

const userStorage = {
    set,
    get,
    remove
}

export default userStorage;
