import userStorage from '../userStorage';
import userStorageKeys from '../userStorage/keys';

function isPublicSignupRequired() {
    return process.env.REACT_APP_PUBLIC_SIGNUP_DISABLED !== 'true';
}

function isUserLoggedIn() {
    return Boolean(userStorage.get(userStorageKeys.LOGGED_IN_USER));
}

function sendSignUpData(data, onSuccess, onError) {
    // Required fields
    const formData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
    };

    // Optional fields
    if (data.phone) {
        formData.phone = data.phone;
    }

    if (data.company) {
        formData.company = data.company;
    }

    fetch('https://www.griddynamics.com/api/subscription/demo/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
    })
    .then(res => {
        if (res.status === 200) {
            onSuccess && onSuccess(data);
            return;
        }

        onError && onError(res);
    })
    .catch((error) => {
        onError && onError(error);
    });
}

function login(data) {
    return userStorage.set(userStorageKeys.LOGGED_IN_USER, data);
}

const authApi = {
    isPublicSignupRequired,
    isUserLoggedIn,
    sendSignUpData,
    login
};

export default authApi;
