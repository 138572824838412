import React from "react";
import { Switch } from "react-router-dom";
import CategoryPage from "../../pages/CategoryPage";
import DemoPage from "../../pages/DemoPage";
import DetailsPage from "../../pages/DetailsPage/DetailsPage";
import PrivateRoute from '../PrivateRoute';

const CategoryRouteSchema = (props) => (
  <Switch>
    <PrivateRoute exact path="/"><CategoryPage {...props} /></PrivateRoute>
    <PrivateRoute exact path="/category/:category"><CategoryPage {...props} /></PrivateRoute>
    <PrivateRoute exact path="/category/:category/demo/:id"><DetailsPage {...props} /></PrivateRoute>
    <PrivateRoute exact path="/category/:category/demo/:id/explore"><DemoPage /></PrivateRoute>
  </Switch>
);

export default CategoryRouteSchema;
