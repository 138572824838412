import React, { useState } from 'react';
import classNames from 'classnames';

import { checkboxContainer, checkbox, checkboxChecked, checkboxTitle } from './Checkbox.module.scss'; 

const Checkbox = ({ checked, style, onChange, children }) => {
  const [ isChecked, setIsChecked ] = useState(checked);

  const checkboxContainerClasses = classNames(
    checkboxContainer,
    style
  );

  const checkboxClasses = classNames(
    checkbox,
    isChecked && checkboxChecked
  );

  const changeHandler = () => {
    const newVal = !isChecked;

    setIsChecked(newVal);
    onChange && onChange(newVal);
  };

  return (
    <div className={checkboxContainerClasses} onClick={changeHandler}>
      <div className={checkboxClasses}></div>
      { children && <span className={checkboxTitle}>{children}</span> }
    </div>
  );
}

export default Checkbox;

