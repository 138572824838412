import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import { box, boxMobile, logo, company, menu, menuBtn, menuBtnActive } from "./Header.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Header = () => {
  const contactLink = "https://www.griddynamics.com/contact";
  const [condition, setCondition] = useState(false);
  const btnClasses = classNames(menuBtn, condition && menuBtnActive);
  const boxClasses = classNames(box, condition && boxMobile);

  return (
    <header>
      <div className="container">
        <div className={boxClasses}>
          <div className={logo}>
            <Link to="/">
              <img src={Logo} alt="Grid Labs Demo" />
            </Link>

            <div className={company}>By Grid Dynamics</div>
          </div>

          <div className={menu}>
            <a className="btn basic" href={contactLink}>
              Get in touch
            </a>
          </div>

          <button
            className={btnClasses}
            onClick={() => setCondition(!condition)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
