import React from "react";
import Card from "../../components/Card";
import { masonryGrid, masonryColumn } from "./Cards.module.scss";
import Masonry from "react-masonry-css";

const Cards = ({ items, category }) => {
  const gridBreakpoints = {
    default: 3,
    1239: 2,
    767: 1,
  };

  return (
    <Masonry
      breakpointCols={gridBreakpoints}
      className={masonryGrid}
      columnClassName={masonryColumn}
    >
      {items.map((demo) => {
        const tagCategory =
          category ||
          (demo.tags.length && demo.tags[0].split(" ").join("-")) ||
          "_";
        const url = "/category/" + tagCategory + "/demo/" + demo.id;

        return (
          <Card
            key={demo.id}
            titleText={demo.title}
            imgUrl={demo.img}
            description={demo.description}
            tags={demo.tags}
            linkTo={url}
          />
        );
      })}
    </Masonry>
  );
};

export default Cards;
