import React from 'react';
import { Route, Redirect } from "react-router-dom";

import { auth as authApi } from "../../services/api";

const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        !authApi.isPublicSignupRequired() || authApi.isUserLoggedIn() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/auth/signup",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export default PrivateRoute;
