import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import SignUpResultModal from './SignUpResultModal';

import { auth as authApi} from '../../services/api';

import { wrap, title, formWrap, nameInputsContainer, inputWrap, checkboxes, checkboxWrap, contactButtonWrap } from './SignUpForm.module.scss';

const inputNameValidation = {
    regExp: /^[a-zA-Z]*$/,
    errorMsg: 'Only letters are allowed'
};

const inputPhoneValidation = {
    regExp: /^\+?\d+$/,
    errorMsg: 'Only digits and optional leading plus sign are allowed'
};

const SignUpForm = () => {
    const history = useHistory();
    const location = useLocation();

    const formRef = useRef(null);

    const [termsChecked, setTermsChecked] = useState(false);
    const [allowContactsChecked, setAllowContactsChecked] = useState(false);
    const [submitBtnPressed, setSubmitBtnPressed] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const login = (data) => {
        const { from } = location.state || { from: { pathname: "/" } };

        authApi.login(JSON.stringify(data));
        formRef.current.reset();

        setTimeout(() => {
            history.replace(from);
        }, 2000);
    };

    const onSubmitSuccess = (data) => {
        setSubmitSuccess(true);
        login(data);
    }

    const onSubmitError = () => {
        setSubmitBtnPressed(false);
        setSubmitError(true);
    }

    const sendSignUpRequest = (data) => {
        authApi.sendSignUpData(data, onSubmitSuccess, onSubmitError);
    }

    const termsChangeHandler = () => {
        setTermsChecked(!termsChecked);
    };

    const allowContactsChangeHandler = () => {
        setAllowContactsChecked(!allowContactsChecked);
    };

    const handleSubmit = async (data) => {
        if (submitBtnPressed) {
            return;
        }

        try {
            formRef.current.setErrors({});

            const schema = Yup.object().shape({
                firstName: Yup.string().matches( inputNameValidation.regExp, { message: inputNameValidation.errorMsg }).required(),
                lastName: Yup.string().matches( inputNameValidation.regExp, { message: inputNameValidation.errorMsg }).required(),
                email: Yup.string().email().required(),
                phone: Yup.string().matches( inputPhoneValidation.regExp, { message: inputPhoneValidation.errorMsg, excludeEmptyString: true }),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            setSubmitBtnPressed(true);
            sendSignUpRequest(data);
        } catch (err) {
            const validationErrors = {};

            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    }

    const tryToClearInputError = (inputName) => {
        const validationErrors = formRef.current.getErrors();

        if (validationErrors[inputName]) {
            delete validationErrors[inputName];
            formRef.current.setErrors(validationErrors);
        }
    }

    const formChangeHandler = (e) => {
        tryToClearInputError(e.target.name);
    };

    return (
        <div className={wrap}>
            <h1 className={title}>Log in to see our demos</h1>
            <div className={formWrap}>
                {
                    <Form ref={formRef} onSubmit={handleSubmit} initialData={{}} onChange={formChangeHandler}>
                        <div className={nameInputsContainer}>
                            <Input name="firstName" type="text" maxLength="100" placeholder="First Name" style={inputWrap} required/>
                            <Input name="lastName" type="text" maxLength="100" placeholder="Last Name" style={inputWrap} required/>
                        </div>
                        <Input name="email" type="email" maxLength="100" placeholder="Email" style={inputWrap} required/>
                        <Input name="phone" type="tel" maxLength="100" placeholder="Phone" style={inputWrap} />
                        <Input name="company" type="text" maxLength="100" placeholder="Company" style={inputWrap} />

                        <div className={checkboxes}>
                            <Checkbox checked={termsChecked} style={checkboxWrap} onChange={termsChangeHandler}>
                                I have read and accepted the <a href='https://www.griddynamics.com/terms-and-conditions'>Terms & Conditions</a> and <a href='https://www.griddynamics.com/privacy'>Privacy Policy</a>.
                            </Checkbox>
                            <Checkbox checked={allowContactsChecked} style={checkboxWrap} onChange={allowContactsChangeHandler}>
                                I allow Grid Dynamics to contact me.
                            </Checkbox>
                        </div>

                        <div className={contactButtonWrap}>
                            <Button
                                type='submit'
                                isPressed={submitBtnPressed}
                                disabled={termsChecked && allowContactsChecked ? false : true}
                            >
                                Log in
                            </Button>
                        </div>
                    </Form>
                }
                <SignUpResultModal isSuccess={true} isOpen={submitSuccess} onClose={() => { setSubmitSuccess(false) }} />
                <SignUpResultModal isSuccess={false} isOpen={submitError} onClose={() => { setSubmitError(false) }} />
            </div>
        </div>
    );
};

export default SignUpForm;
