import React, { useEffect, useState } from "react";
import TagsMenu from "../../components/TagsMenu";
import Category from "../../components/Category";
import { getDemos } from "../../services/api";

const HomePage = () => {
  const [demos, setDemos] = useState([]);

  useEffect(() => {
    getDemos().then((data) => {
      setDemos(data);
    });
  }, []);

  return (
    <>
      <TagsMenu />
      <Category demos={demos} />
    </>
  );
};

export default HomePage;