import React from 'react';
import { Player, BigPlayButton } from 'video-react';
import './styles.scss';

const Video = ({ source, thumb }) => {
  return (
    <Player
      src={source}
      poster={thumb}
      height={window.innerWidth < 600 ? 260 : 316}
      fluid={false}
      width="100%"
    >
      <BigPlayButton position="center" />
    </Player>
  );
};

export default Video;
