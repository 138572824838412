import React from "react";
import { useParams } from "react-router-dom";
import { wrap, intro } from "./Category.module.scss";
import Cards from "../../components/Cards";

const CategoryPage = ({ demos }) => {
  const { category } = useParams();
  const isHomePage = !category;
  const tag = category && category.split("-").join(" ");
  const filteredDemos = category
    ? demos.filter((item) => item.tags.includes(tag))
    : demos;
  const introTxt = ""; // TBD

  return (
    <div className="container">
      <section className={wrap}>
        {isHomePage && (
          <>
            <h1>Demos</h1>
            {introTxt && <div className={intro}>{introTxt}</div>}
          </>
        )}

        {category && <h1>{tag}</h1>}

        <Cards items={filteredDemos} category={category} />
      </section>
    </div>
  );
};

export default CategoryPage;
