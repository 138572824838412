import React from "react";
import { Link } from "react-router-dom";
import { card, title, content, link, textWrap, tagsList } from "./Card.module.scss";

const Card = ({ titleText, imgUrl, description, linkTo, tags }) => {
  const txtLimit = 300;

  return (
    <Link className={card} to={linkTo}>
      <figure>
        <img src={imgUrl} alt="" />
      </figure>

      <div className={textWrap}>
        <div className={title}>{titleText}</div>
        <div className={content}>
          {description.length > txtLimit
            ? description.substring(0, txtLimit - 3) + "..."
            : description}
        </div>
        <ul className={tagsList}>
          {tags.map((tag, index) => (
            <li key={index}>{tag}</li>
          ))}
        </ul>
        <div className={link}>
          Learn More <span>→</span>
        </div>
      </div>
    </Link>
  );
};

export default Card;
