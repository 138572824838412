import React, { useEffect, useState, Fragment } from "react";
import Video from "../../components/Video";
import { getDemo, auth as authApi } from "../../services/api";
import { overviewBlock, faqBlock, featuresBlock, description, links, familiarCases } from "./DetailsPage.module.scss";
import { useParams, Link } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs";
import DemoDetailsSection from "../../components/DemoDetailsSection";
import DemoFaq from "../../components/DemoFaq";
import DemoFiles from "../../components/DemoFiles";
import DemoTestimonials from "../../components/DemoTestimonials";
import DemoFeatures from "../../components/DemoFeatures";
import DemoTechnologies from "../../components/DemoTechnologies";
import DemoExploreCTA from "../../components/DemoExploreCTA";
import Cards from "../../components/Cards";

const DetailsPage = ({ demos }) => {
  const [demo, setDemo] = useState({ sections: [] });
  let { category, id } = useParams();
  const categoryTitle = category.split("-").join(" ");
  const demoTitle = id.split("-").join(" ");
  const breadcrumbsLinks = [
    {
      url: `/category/${category}`,
      text: categoryTitle,
    },
    {
      url: null,
      text: demoTitle,
    },
  ];
  const isDemoLinkDisabled = authApi.isPublicSignupRequired();
  const undefinedCategory = "_";
  const familiarDemos = category && category !== undefinedCategory
  ? demos.filter((item) => item.tags.includes(categoryTitle) && item.id !== id)
  : [];
  const isData = !!demo.sections.length;

  useEffect(() => {
    getDemo(id).then((data) => {
      setDemo(data);
    });
  }, [id]);

  return (
    <div>
      <div className="container">
        <Breadcrumbs links={breadcrumbsLinks} />
      </div>
      <div>
        {
          demo.sections.map((section, index) => (
              <Fragment key={index}>
              <DemoDetailsSection type="overview" style={overviewBlock}>
                  <h1>{section.title}</h1>
                  <Video source={section.video_link} thumb={section.thumbnail_link} />
                  {
                    section.technologies && section.technologies.length > 0 &&
                      <DemoTechnologies data={section.technologies} />
                  }
                  <div
                    className={description}
                    dangerouslySetInnerHTML={{ __html: section.description }}
                  ></div>
                  <div className={links}>
                    {section.links.map((link) =>
                      link.is_website ? (
                        !isDemoLinkDisabled && <Link
                          className="btn cta"
                          key={link.name}
                          to={`/category/${category}/demo/${id}/explore`}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <a className="btn cta" key={link.name} href={link.link}>
                          {link.name}
                        </a>
                      )
                    )}
                  </div>
                </DemoDetailsSection>
                {
                  section.features && section.features.length > 0 &&
                    <DemoDetailsSection style={featuresBlock}><DemoFeatures data={section.features} /></DemoDetailsSection>
                }
                {
                  section.faq && section.faq.length > 0 &&
                    <DemoDetailsSection noDivider style={faqBlock}><DemoFaq data={section.faq} /></DemoDetailsSection>
                }
                {
                  section.files && section.files.length > 0 &&
                    <DemoDetailsSection noDivider><DemoFiles data={section.files} /></DemoDetailsSection>
                }
                {
                  !isDemoLinkDisabled && 
                    <DemoDetailsSection type="explore"><DemoExploreCTA demoUrl={`/category/${category}/demo/${id}/explore`} /></DemoDetailsSection>
                }
                {
                  section.testimonials && section.testimonials.length > 0 &&
                    <DemoDetailsSection><DemoTestimonials data={section.testimonials} /></DemoDetailsSection>
                }
            </Fragment>
          ))
        }
      </div>

      {isData && familiarDemos.length > 0 && (
        <div className="container">
          <div className={familiarCases}>
            <h2 className="demoDetailsTitle">
              More cases in <span>{categoryTitle}</span>
            </h2>
            <Cards items={familiarDemos} category={category} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPage;
