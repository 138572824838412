import butter from '../butter';
import { mapDemoCard, mapDemo } from '../demos';
import authApi from './auth';

export function getDemos(params = {levels: 2}) {
  return butter.content.retrieve(['demo'], params).then(res => {
    const demos = res.data.data.demo;
    return demos.map(mapDemoCard);
  });
}

export function getDemo(id) {
  const params = { 'fields.id': id, levels: 0 };

  return butter.content.retrieve(['demo'], params).then((res) => {
    const demo = res.data.data.demo[0];

    return mapDemo(demo);
  });
}

export function getDemoAppsData() {
  return butter.content.retrieve(['demo_link']).then(res => res.data.data.demo_link);
}

export function getDemoAppData(id) {
  return getDemoAppsData().then(data => data.find(item => item.demo_id === id));
}

export function getTags() {
  return butter.content.retrieve(['demo_tag'])
    .then(res => res.data.data.demo_tag.map(({ title }) => title));
}

export const auth = authApi;
