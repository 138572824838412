import React from 'react';
import classNames from 'classnames';

import { wrap, titlePart, titleEl, filesListPart, filesListWrap, fileWrap, fileIcon, fileLink, presentationType, newspaperType } from './DemoFiles.module.scss'; 

const DemoFile = ({ name, type, url }) => {
  const fileType = type.type;

  const fileWrapClasses = classNames(
    fileWrap,
    fileType === 'presentation' && presentationType,
    fileType === 'document' && newspaperType,
  );

  return (
    <li className={fileWrapClasses}>
      <div className={fileIcon}></div>
      <a className={fileLink} href={url}>{name}</a>
    </li>
  );
}

const DemoFilesList = ({ data }) => {
  return (
    <div className={wrap}>
      <div className={titlePart}>
        <h2 className={classNames('demoDetailsTitle', titleEl)}>Files to consider:</h2>
      </div>
      <div className={filesListPart}>
        <ul className={filesListWrap}>
          {
            data.map((fileData, index) => <DemoFile key={index} {...fileData} />)
          }
        </ul>
      </div>
    </div>
  );
}

export default DemoFilesList;
